import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Hardwaremanagement from "../../../components/ProductDetails/netversys/Hardwaremanagement"
import Seo from "../../../components/App/Seo"

const HardwarePage = () => {
  return (
    <Layout>
      <Seo title={"Hardwaremanagement auf höchstem Niveau."}
           description={"NETVERSYS verwaltet jede Hardware eigenständig und spricht " +
           "diese auch an. Hohe Geschwindigkeit. Keine Treiber. Keine zusätzlichen Installationen."}
           image={"/images/og/netversys-main-og.png"}>
        <script type="application/ld+json">{`
              {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "https://logentis.de"
                },{
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Versandsoftware",
                  "item": "https://logentis.de/versandsoftware/"
                },{
                  "@type": "ListItem",
                  "position": 3,
                  "name": "Hardware",
                  "item": "https://logentis.de/versandsoftware/netversys/hardware/"
                }]
              }
              `}
        </script>
      </Seo>
      <Navbar />
      <Hardwaremanagement/>
      <Footer />
    </Layout>
  )
}

export default HardwarePage
