import React from "react"
import hardwareManagementHeader from "../../../assets/images/products/netversys/hardwaremanagement_header.svg"
import wmHardwareManagement from "../../../assets/images/products/netversys/wm-hardwaremanagement.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"
import { StaticImage } from "gatsby-plugin-image"

const Hardwaremanagement = () => {

  return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={hardwareManagementHeader} alt="about" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETVERSYS</span>
                <h1 className={"pt-3"}>Hardwaremanagement</h1>
                <p>
                  NETVERSYS verwaltet jede benötigte Hardware eigenständig und spricht auch, wenn gewollt, ohne Umwege
                  direkt mit den Geräten. Die Folge sind schnelle Kommunikation und die größtmögliche Unabhängigkeit zum
                  Betriebssystem. Die Zeiten, als unter Windows ein Drucker angelegt werden und dieser - mitunter
                  umständlich - in das Versandsystem integriert werden musste, sind bei NETVERSYS vorbei. NETVERSYS
                  bietet hierbei Kompatibilität zu den großen Herstellern von Druckern und Waagen an. Auch die GLSBox
                  wird in ähnlicher Weise verwaltet. Die verschiedenen Devices werden am Ende zu Arbeitsplätzen
                  geschnürt, welche die konkrete Arbeitsumgebung eines Versandmitarbeiters darstellen.
                </p>

                <p>
                  Hardware und Arbeitsplätze können außerdem mit Objekteigenschaften versehen werden, welche von
                  Fremdsystemen benutzt werden können. So nutzen bereits heute verschiedene WMS Systeme die von
                  NETVERSYS verwaltete Hardware, um über Webservices Operationen auf ihnen auszuführen. Kennen Sie ein
                  Versandsystem welches von außen angesprochen werden kann, um auf einem Labeldrucker etwas ganz anderes
                  zu drucken als ein Versandlabel? NETVERSYS bietet Interoperabilität in einer neuen Dimension.
                </p>

                <WichtigeMerkmale items={[
                  "Hardware unabhängig von Software",
                  "100% unabhängig vom Betriebssystem",
                  "Verwaltung außerhalb vom OS",
                  "direkter und schneller Zugriff"
                ]} image={<img src={wmHardwareManagement}
                               alt="Hardwaremanagement Merkmale"
                               className={"ml-lg-4 ml-0"} />} />

                <h3>Relevantes aus dem Lexikon</h3>

                <LexikonBadgesRandomSection />
              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="services-area pt-4 pb-70 bg-f1f8fb">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-bullseye"} /> Im Detail</span>
          <h2>Hardware in NETVERSYS verwalten</h2>
          <Row className={"mt-5"}>
            <Col md={6} xs={12}>
              <StaticImage
                src="../../../assets/images/products/netversys/screenshots/nv_workplace_framed.png"
                alt="Hardwaremanagement Screenshot"
                placeholder="blurred"
                width={600}
              />
            </Col>
            <Col md={{ offset: 1, span: 5 }} xs={{ offset: 1, span: 11 }}>
              <p>
                Der Arbeitsplatz und seine Hardware ist ein zentrales Element von NETVERSYS. Der Mitarbeiter meldet sich
                zu Beginn seiner
                Arbeitsschicht an einem Arbeitsplatz an und teilt so dem System mit, wo er gerade genau arbeitet. Dies
                ist nötig, da NETVERSYS verschiedenste Devices wie z.B. auch Wifi-Barcodehandhelds zur Steuerung
                unterstützt und sich der Mitarbeiter mit seinem Gerät somit an jedem beliebigen Arbeitsplatz anmelden
                kann.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Hardwaremanagement
